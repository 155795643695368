import React from "react"
import styled from "styled-components"
import { Layout } from "@/layout"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { useScrollYPosition } from "react-use-scroll-position"
import {
  Box,
  CTA,
  SEO,
  Width,
  Heading,
  Text,
  SmallWave,
  Mastery,
  LessonMap,
  MentorAlert,
  List,
  ListItem,
  ArrowAlt,
  VideoPlayer,
  CalComplete,
  CalIncomplete,
  // PromoBanner,
  Carousel,
  Slide,
  Link,
  ArrowRight,
  Span,
} from "@/components/index"
import { color } from "@/theme/index"
import { useLocation } from "@reach/router"

import { trackAffiliate } from "@/utils/affiliateTracking"

export default function Home({ data }) {
  const location = useLocation()
  trackAffiliate(location)
  const scrollY = useScrollYPosition()
  const headerTheme = scrollY > 150 ? "light" : "dark"
  let nextMonday = new Date()
  nextMonday.setDate(nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7))
  const nextMondayDate =
    nextMonday.getMonth() +
    1 +
    "/" +
    nextMonday.getDate() +
    "/" +
    nextMonday.getFullYear()
  return (
    <Layout headerType="default" headerTheme={headerTheme} footerTheme="dark">
      <SEO />
      <Box>
        {/* Hero */}
        <Box
          height="100%"
          color="white"
          position="relative"
          zIndex="3"
          top="0"
          width="100%"
          display="flex"
          flexDirection="column"
          textAlign="center"
          backgroundImage="linear-gradient(79.88deg, #1B0646 27.59%, #5128AE 100.28%)"
          pt={["12.5rem", null, null, "10rem"]}
          pb={["4.8rem", null, null, "15rem"]}
          variant="slopeBottomRight"
        >
          <Width
            display="flex"
            flexDirection={["column", null, "row", null]}
            textAlign="left"
            alignItems="center"
            flex="1"
            pt={["0", null, null, "10rem"]}
            pb="3.6rem"
          >
            <Box
              width={["100%", "80%", 5 / 12, null]}
              mr={["auto", null, "3.6rem", null]}
              ml={["auto", null, 0, null]}
              order={[2, null, 1, null]}
              textAlign={["center", null, "left", null]}
            >
              <Heading
                mb="1.8rem"
                mx={["auto", 0, null, null]}
                color={color.thulite}
              >
                Unlock fretboard <br /> fluency, faster
              </Heading>
              <Text>
                Made for intermediate guitar players, Sonora offers 12-48 week intensive
                programs to help you break through multi-year plateaus and find
                your creative freedom on guitar.
              </Text>
              <CTA variant="special" to="/application" mb="1.2rem" mt="3rem">
                Apply Now
              </CTA>
              {/* <Text variant="small">No obligation, cancel anytime.</Text> */}
            </Box>

            <VideoPlayer
              width={["100%", "80%", 7 / 12, null]}
              mx={["auto", null, 0, null]}
              mb={["3rem", null, null, "0"]}
              order={[1, null, 2, null]}
            />
          </Width>
        </Box>

        {/* Accelerated Learning */}
        <Width pt="3.6rem" pb="6rem">
          <Box
            width={["100%", 10 / 12, 8 / 12, 6 / 12]}
            mx="auto"
            mb="5rem"
            textAlign="center"
          >
            <SmallWave display="block" mx="auto" mb="1.2rem" />
            <Heading level="2" mb="3.6rem">
              Level up with accelerated learning
            </Heading>
            <Img
              alt="Accelerated Learning"
              fluid={data.levelupImage.childImageSharp.fluid}
            />
            <Text mt="3.6rem">
              Sonora combines cutting-edge software with expert instruction to
              help you rapidly grow your musicianship.
            </Text>
          </Box>
          <Box display={["block", null, "flex", null]}>
            <Box
              width={["100%", "80%", 4 / 12, null]}
              p="2.4rem"
              borderRadius=".6rem"
              textAlign="center"
              mb={["2.4rem", null, "0"]}
              bg="#FFF7F4"
              ml={["auto", null, 0, null]}
              mr={["auto", null, "1.8rem", null]}
            >
              <Box
                display="flex"
                mb="0.6rem"
                alignItems="center"
                justifyContent={["center", null, "flex-start"]}
              >
                <Mastery mr="0.8rem" />
                <Text fontWeight="bold" color="amethyst">
                  Intelligent coaching
                </Text>
              </Box>
              <Text level={2}>
                Absorb, polish, and retain core concepts through accelerated
                learning and language acquisition techniques.
              </Text>
            </Box>
            <Box
              width={["100%", "80%", 4 / 12, null]}
              p="2.4rem"
              textAlign="center"
              mb={["2.4rem", null, "0"]}
              bg={color.opal}
              borderRadius=".6rem"
              ml={["auto", null, 0, null]}
              mr={["auto", null, "1.8rem", null]}
            >
              <Box
                display="flex"
                mb="0.6rem"
                alignItems="center"
                justifyContent={["center", null, "flex-start"]}
              >
                <LessonMap mr="0.5rem" />
                <Text fontWeight="bold" color="amethyst">
                  Custom practice plans
                </Text>
              </Box>
              <Text level={2}>
                Know exactly what to work on every day without wasting a single
                minute of practice time.
              </Text>
            </Box>
            <Box
              width={["100%", "80%", 4 / 12, null]}
              p="2.4rem"
              textAlign="center"
              bg={color.agate}
              borderRadius=".6rem"
              ml={["auto", null, 0, null]}
              mr={["auto", null, 0, null]}
            >
              <Box
                display="flex"
                mb="0.6rem"
                alignItems="center"
                justifyContent={["center", null, "flex-start"]}
              >
                <MentorAlert mr="0.8rem" />
                <Text fontWeight="bold" color="amethyst">
                  Streamlined feedback
                </Text>
              </Box>
              <Text level={2}>
                Chat with your mentor in real-time. You’ll also get detailed
                feedback when you share videos of your guitar playing.
              </Text>
            </Box>
          </Box>
          <Box width="100%" mx="auto" textAlign="center">
            <CTA to="/application" mt="3.6rem">
              Apply
            </CTA>
          </Box>
        </Width>

        {/* Fretboard Fluency */}
        <Box bg={color.amethyst} variant="slopeBottomLeft" pt="6rem">
          <Width
            display={["block", null, "flex", null]}
            justifyContent="space-between"
            color={color.white}
          >
            <Box width={["100%", null, 5 / 12, null]}>
              <SmallWave
                display="block"
                mb="1.2rem"
                mx={["auto", null, 0, null]}
              />
              <Heading
                level={2}
                mb="2rem"
                textAlign={["center", null, "left", null]}
                color={color.thulite}
              >
                A clear path to <br />
                fretboard fluency
              </Heading>
              <Text
                mb="2.4rem"
                textAlign={["center", null, "left", null]}
                width={["100%", "80%", "100%", null]}
                mx={["auto", null, 0, null]}
              >
                Our curriculum helps you achieve a complete understanding of the
                foundations of music, <em>regardless of your playing style</em>.
              </Text>
              <Box
                maxWidth={["30rem", null, "80%", null]}
                mx={["auto", null, 0, null]}
                mb={["3.6rem", null, 0, null]}
              >
                <Img
                  fluid={data.curriculumImage.childImageSharp.fluid}
                  alt="Curriculum Mastery"
                />
              </Box>
            </Box>
            <Box
              width={["100%", "80%", 6 / 12, null]}
              mx={["auto", null, 0, null]}
              textAlign={["center", null, "left", null]}
            >
              <Heading as="h4" level={3} color={color.thulite}>
                Over 20 years experience
              </Heading>
              <Text level={2} mb="3.6rem">
                Developed by deeply experienced music educators, our
                comprehensive curriculum identifies the key skills required to
                advance your musicianship.
              </Text>
              <Heading as="h4" level={3} color={color.thulite}>
                Successfully tested by thousands
              </Heading>
              <Text level={2} mb="3.6rem">
                <em>
                  <u>Our curriculum is proven.</u>
                </em>{" "}
                Over a thousand Sonora students have successfully broken through
                their learning plateaus and unlocked fretboard fluency.
              </Text>
              <Heading as="h4" level={3} color={color.thulite}>
                Years of study condensed into months
              </Heading>
              <Text level={2}>
                Our students make more progress in 3-6 months of intensive study
                in our program than most students make in several years.
              </Text>
            </Box>
          </Width>
          <Box width="100%" mx="auto" textAlign="center">
            <CTA to="/application" variant="tertiary" mt="3.6rem">
              Apply
            </CTA>
          </Box>
        </Box>

        {/* Worldclass Mentorship */}
        <Box bg={color.opal_hover} variant="slopeTopRight" mt="-3.6rem">
          <Width
            display={["block", null, "flex", null]}
            pb="6rem"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Box width={["100%", null, 5 / 12, null]}>
              <SmallWave
                display="block"
                mb="1.2rem"
                mx={["auto", null, 0, null]}
              />
              <Heading
                level="2"
                mb="2rem"
                textAlign={["center", null, "left", null]}
              >
                Worldclass Mentorship
              </Heading>
              <Text
                mb="2.4rem"
                textAlign={["center", null, "left", null]}
                width={["100%", "80%", "100%", null]}
                mx={["auto", null, 0, null]}
              >
                Get 1-on-1 guidance from the world’s top guitarists and
                educators with Sonora’s Mentorship Program
              </Text>
              <Box
                width={["100%", "60%", "100%", null]}
                mx={["auto", null, 0, null]}
              >
                <List bullets="checkmark">
                  <ListItem>
                    <span>
                      <strong>Make progress every day</strong> with direct
                      mentorship and Sonora’s streamlined feedback loop.
                    </span>
                  </ListItem>
                  <ListItem>
                    <span>
                      <strong>Get an expert opinion </strong> and detailed
                      feedback on your technique from your mentor.
                    </span>
                  </ListItem>
                  <ListItem>
                    <span>
                      <strong>Unlimited access to your mentor</strong> for any
                      questions you might have.
                    </span>
                  </ListItem>
                </List>
                <CTA
                  to="/mentorship"
                  variation="primary"
                  mt="3.6rem"
                  mb={["3.6rem", null, 0, null]}
                  ml={[0, "1.8rem", 0, null]}
                >
                  Meet the Mentors
                </CTA>
              </Box>
            </Box>

            <Box
              width={["100%", "80%", 6 / 12, null]}
              px="2.4rem"
              pb="2.2rem"
              bg="#F8FAFD"
              borderRadius="0.6rem"
            >
              <Carousel>
              <Slide pt="2.4rem" pb="4.4rem" debug="green">
                  <VideoPlayer
                    url="https://vimeo.com/498089798/d8e7f56de0"
                    mb="1.8rem"
                    width="auto"
                    maxWidth="auto"
                  />
                  <Heading level="2">Brian Green </Heading>
                  <Text>Touring and session guitarist</Text>
                  <Text level={2} my="1.8rem">
                    Brian Green is a guitar player and producer based out of Los
                    Angeles, California. He has toured, performed and/or
                    recorded with artists including John Legend, Rufus
                    Wainwright, Michael Bublé, Nayo, and Kat Mcphee.
                  </Text>
                  <Text
                    fontSize="14px"
                    fontWeight="bold"
                    textTransform="uppercase"
                  >
                    <Link
                      to="/mentorship/brian-green/"
                      variant="smallUppercase"
                    >
                      Meet Brian <ArrowRight ml="0.6rem" />
                    </Link>
                  </Text>
                </Slide>
                <Slide pt="2.4rem" pb="4.4rem" debug="green">
                  <VideoPlayer
                    url="https://vimeo.com/493924451/65cb6cfa8d"
                    mb="1.8rem"
                    width="auto"
                    maxWidth="auto"
                  />
                  <Heading level="2">Curt Henderson</Heading>
                  <Text>Guitarist and producer</Text>
                  <Text level={2} my="1.8rem">
                    Nashville-based Curt Henderson has played, recorded, and
                    toured internationally with major artists like EZA (Ellery
                    Bonham), John King, and worked with American Idol finalist
                    Jonny Brenns
                  </Text>
                  <Text
                    fontSize="14px"
                    fontWeight="bold"
                    textTransform="uppercase"
                  >
                    <Link
                      to="/mentorship/curt-henderson/"
                      variant="smallUppercase"
                    >
                      Meet Curt <ArrowRight ml="0.6rem" />
                    </Link>
                  </Text>
                </Slide>
                {/* slide 05 */}
                
                <Slide pt="2.4rem" pb="4.4rem" debug="green">
                  <VideoPlayer
                    url="https://vimeo.com/495902965/577e5e7dab"
                    mb="1.8rem"
                    width="auto"
                    maxWidth="auto"
                  />
                  <Heading level="2">Robb Cappelletto</Heading>
                  <Text>Guitarist, Composer and Educator </Text>
                  <Text level={2} my="1.8rem">
                    Robb Cappelletto is a guitarist, composer and educator based
                    in Toronto, Canada. He has two decades of professional
                    teaching experience and has been on faculty at York
                    University as a jazz guitar instructor since 2007.
                  </Text>
                  <Text
                    fontSize="14px"
                    fontWeight="bold"
                    textTransform="uppercase"
                  >
                    <Link
                      to="/mentorship/robb-cappelletto/"
                      variant="smallUppercase"
                    >
                      Meet Robb <ArrowRight ml="0.6rem" />
                    </Link>
                  </Text>
                </Slide>
              </Carousel>
            </Box>
          </Width>
        </Box>

        {/* Modern Study */}
        <Width pt="3.6rem" pb="6rem">
          <Box
            width={["100%", 10 / 12, 8 / 12, 6 / 12]}
            mx="auto"
            mb="5rem"
            textAlign="center"
          >
            <SmallWave display="block" mx="auto" mb="1.2rem" />
            <Heading level="2" mb="2rem">
              A modern approach to study
            </Heading>
            <Text>
              Unlike private lessons, Sonora's Mentored students get unlimited
              access to feedback so learning and progress can happen every day –
              not just once a week.
            </Text>
          </Box>
          <Box
            display="flex"
            flexDirection={["column", null, "row", null]}
            mx="auto"
          >
            <Box
              width={["100%", 10 / 12, 5 / 12, null]}
              textAlign="center"
              mx="auto"
            >
              <Text
                fontSize="14px"
                fontWeight="bold"
                color={color.tanzanite}
                mb=".6rem"
              >
                THE OLD WAY
              </Text>
              <Text as="h3" color="amethyst" mb="0.6rem">
                Traditional lessons
              </Text>
              <Box display="flex" justifyContent="center" pt=".6rem" pb="3rem">
                <CalComplete />
                <CalIncomplete />
                <CalIncomplete />
                <CalIncomplete />
                <CalIncomplete />
                <CalIncomplete />
                <CalIncomplete />
              </Box>
              <Text level="2">
                Students met with an instructor just once a week. For the
                remainer of that week, students were more likely to practice bad
                habits and progress slowly.
              </Text>
            </Box>
            <Box
              width={["100%", null, 2 / 12, null]}
              display="flex"
              alignItems="center"
              mx={["0", null, "3.6rem", null]}
              mt={["3.6rem", null, 0, null]}
              mb={["2.4rem", null, "0", null]}
            >
              <ArrowAlt
                // eslint-disable-next-line
                transform={["rotate(90deg)", null, "rotate(0deg)"]}
                mx="auto"
                stroke="#B3A8CB"
              />
            </Box>
            <Box
              width={["100%", 10 / 12, 5 / 12, null]}
              textAlign="center"
              mx="auto"
            >
              <Text
                fontSize="14px"
                fontWeight="bold"
                color={color.tanzanite}
                mb=".6rem"
              >
                THE MODERN WAY
              </Text>
              <Text as="h3" color="amethyst" mb="0.6rem">
                Sonora's anytime framework
              </Text>
              <Box display="flex" justifyContent="center" pt=".6rem" pb="3rem">
                <CalComplete />
                <CalComplete />
                <CalComplete />
                <CalComplete />
                <CalComplete />
                <CalComplete />
                <CalComplete />
              </Box>
              <Text level="2">
                Students get unlimited access to a mentor, 7 days a week. Along
                with a custom daily practice plan, forward progress can happen
                rapidly and at any time.
              </Text>
            </Box>
          </Box>
          <Box width="100%" mx="auto" textAlign="center">
            <CTA to="/application" variation="primary" mt="3.6rem">
              Apply
            </CTA>
          </Box>
        </Width>

        {/* Student Stories */}
        <Box
          py="6rem"
          bg={color.tanzanite}
          color={color.thulite}
          variant="slopeTopLeft"
        >
          <Width>
            <SmallWave display="block" mx="auto" mb="1.2rem" />
            <Heading mb="3.6rem" textAlign="center">
              Student Stories
            </Heading>
            <Carousel>
              <Slide pt="2.4rem" pb="4.4rem" debug="green">
                <Box display="flex" flexDirection={["column", null, "row"]}>
                  <VideoPlayer
                    url="https://vimeo.com/495909648/583785dc45"
                    width={["100%", "80%", 6 / 12, null]}
                    mb={["3rem", null, 0, null]}
                    mr={["auto", null, "2rem", null]}
                    ml={["auto", null, 0, null]}
                  />
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    width={["100%", "80%", 6 / 12, null]}
                    borderLeft={`2px solid rgba(255, 255, 255, 0.125)`}
                    pl="1.6rem"
                    mx={["auto", null, 0, null]}
                  >
                    <Text level={1}>
                      “It’s crazy how much better I’m getting! The biggest thing
                      is how much FUN I’m having when I’m soloing now… It’s like
                      you can see something that was invisible, it’s like
                      opening another eye.”
                    </Text>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      mt="3.6rem"
                    >
                      <Heading level={2}>— Erik L.</Heading>
                      <Box
                        display="flex"
                        alignItems="flex-end"
                        mt="-.6rem"
                        ml="2rem"
                      >
                        <Img
                          fixed={data.locationImage.childImageSharp.fixed}
                          alt="Location Ping"
                        />
                        <Text level={2} ml="1rem">
                          China
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Slide>

              <Slide pt="2.4rem" pb="4.4rem" debug="green">
                <Box display="flex" flexDirection={["column", null, "row"]}>
                  <VideoPlayer
                    url="https://vimeo.com/495898988/2a9cbea29e"
                    width={["100%", "80%", 6 / 12, null]}
                    mb={["3rem", null, 0, null]}
                    mr={["auto", null, "2rem", null]}
                    ml={["auto", null, 0, null]}
                  />
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    width={["100%", "80%", 6 / 12, null]}
                    borderLeft={`2px solid rgba(255, 255, 255, 0.125)`}
                    pl="1.6rem"
                    mx={["auto", null, 0, null]}
                  >
                    <Text level={1}>
                      "Sonora offers a really great line up of several female
                      guitarists that you can work with as your mentor… it was
                      super awesome!”
                    </Text>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      mt="3.6rem"
                    >
                      <Heading level={2}>— Leah G.</Heading>
                      <Box
                        display="flex"
                        alignItems="flex-end"
                        mt="-.6rem"
                        ml="2rem"
                      >
                        <Img
                          fixed={data.locationImage.childImageSharp.fixed}
                          alt="Location Ping"
                        />
                        <Text level={2} ml="1rem">
                          Nashville, TN
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Slide>

              <Slide pt="2.4rem" pb="4.4rem" debug="green">
                <Box display="flex" flexDirection={["column", null, "row"]}>
                  <VideoPlayer
                    url="https://vimeo.com/495909821/66f5f56a8d"
                    width={["100%", "80%", 6 / 12, null]}
                    mb={["3rem", null, 0, null]}
                    mr={["auto", null, "2rem", null]}
                    ml={["auto", null, 0, null]}
                  />
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    width={["100%", "80%", 6 / 12, null]}
                    borderLeft={`2px solid rgba(255, 255, 255, 0.125)`}
                    pl="1.6rem"
                    mx={["auto", null, 0, null]}
                  >
                    <Text level={1}>
                      “The people that make up the team are so incredibly
                      supportive, knowledgeable, sincere, and genuine about
                      wanting you to get better. It’s really an awesome program
                      and I couldn’t be more grateful!”
                    </Text>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      mt="3.6rem"
                    >
                      <Heading level={2}>— Lila A.</Heading>
                      <Box
                        display="flex"
                        alignItems="flex-end"
                        mt="-.6rem"
                        ml="2rem"
                      >
                        <Img
                          fixed={data.locationImage.childImageSharp.fixed}
                          alt="Location Ping"
                        />
                        <Text level={2} ml="1rem">
                          Brooklyn, NY
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Slide>

              <Slide pt="2.4rem" pb="4.4rem" debug="green">
                <Box display="flex" flexDirection={["column", null, "row"]}>
                  <VideoPlayer
                    url="https://vimeo.com/495898725/00be0249c2"
                    width={["100%", "80%", 6 / 12, null]}
                    mb={["3rem", null, 0, null]}
                    mr={["auto", null, "2rem", null]}
                    ml={["auto", null, 0, null]}
                  />
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    width={["100%", "80%", 6 / 12, null]}
                    borderLeft={`2px solid rgba(255, 255, 255, 0.125)`}
                    pl="1.6rem"
                    mx={["auto", null, 0, null]}
                  >
                    <Text level={1}>
                      “My playing has left the pentatonic box. It’s gone out of
                      the little ‘safety playpen’ of 1-4-5… I appreciate this
                      roadmap, this way of learning, and the community that’s
                      built around it.”
                    </Text>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      mt="3.6rem"
                    >
                      <Heading level={2}>— David M.</Heading>
                      <Box
                        display="flex"
                        alignItems="flex-end"
                        mt="-.6rem"
                        ml="2rem"
                      >
                        <Img
                          fixed={data.locationImage.childImageSharp.fixed}
                          alt="Location Ping"
                        />
                        <Text level={2} ml="1rem">
                          Los Angeles, CA
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Slide>

              <Slide pt="2.4rem" pb="4.4rem" debug="green">
                <Box display="flex" flexDirection={["column", null, "row"]}>
                  <VideoPlayer
                    url="https://vimeo.com/495898172/fe3a021ab4"
                    width={["100%", "80%", 6 / 12, null]}
                    mb={["3rem", null, 0, null]}
                    mr={["auto", null, "2rem", null]}
                    ml={["auto", null, 0, null]}
                  />
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    width={["100%", "80%", 6 / 12, null]}
                    borderLeft={`2px solid rgba(255, 255, 255, 0.125)`}
                    pl="1.6rem"
                    mx={["auto", null, 0, null]}
                  >
                    <Text level={1}>
                      “I can’t remember the last time I experienced waking up in
                      the morning and really wanting to grab the guitar and
                      start playing… it’s an awesome feeling, and GMI (Sonora)
                      gave me that.”
                    </Text>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      mt="3.6rem"
                    >
                      <Heading level={2}>— Francesca B.</Heading>
                      <Box
                        display="flex"
                        alignItems="flex-end"
                        mt="-.6rem"
                        ml="2rem"
                      >
                        <Img
                          fixed={data.locationImage.childImageSharp.fixed}
                          alt="Location Ping"
                        />
                        <Text level={2} ml="1rem">
                          Italy
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Slide>

              <Slide pt="2.4rem" pb="4.4rem" debug="green">
                <Box display="flex" flexDirection={["column", null, "row"]}>
                  <VideoPlayer
                    url="https://vimeo.com/495898443/6840a02942"
                    width={["100%", "80%", 6 / 12, null]}
                    mb={["3rem", null, 0, null]}
                    mr={["auto", null, "2rem", null]}
                    ml={["auto", null, 0, null]}
                  />
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    width={["100%", "80%", 6 / 12, null]}
                    borderLeft={`2px solid rgba(255, 255, 255, 0.125)`}
                    pl="1.6rem"
                    mx={["auto", null, 0, null]}
                  >
                    <Text level={1}>
                      “I’m starting to discover a sound… I’m a lot more
                      comfortable and a lot more confident in my playing. I feel
                      I am well on my way to becoming the guitarist that I am
                      imagining!”
                    </Text>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      mt="3.6rem"
                    >
                      <Heading level={2}>— Alonzo E.</Heading>
                      <Box
                        display="flex"
                        alignItems="flex-end"
                        mt="-.6rem"
                        ml="2rem"
                      >
                        <Img
                          fixed={data.locationImage.childImageSharp.fixed}
                          alt="Location Ping"
                        />
                        <Text level={2} ml="1rem">
                          Los Angeles, CA
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Slide>
            </Carousel>
          </Width>
        </Box>

        {/* Grow With Our Community */}
        <Width pt="3.6rem">
          <Box
            width={["100%", 10 / 12, 8 / 12, 6 / 12]}
            mx="auto"
            textAlign="center"
            variant="slopeBottomRight"
          >
            <SmallWave display="block" mx="auto" mb="1.2rem" />
            <Heading level="2" mb="2rem">
              Grow with our community
            </Heading>
            <Text>
              Sonora connects you with other intermediate guitar players
              pursuing creative fluency.
            </Text>
            <Box my="3.6rem">
              <Img
                fluid={data.communityImage.childImageSharp.fluid}
                alt="Grow With Our Community"
              />
            </Box>
            <Text level="2">
              Our community is a great place to find practice buddies, share
              progress, get feedback, and cheer each other on — all with the
              support of the whole Sonora team.
            </Text>
            <CTA to="/application" variation="primary" mt="3.6rem">
              Apply
            </CTA>
          </Box>
        </Width>
        {/* Programs */}
        <Box
          height="100%"
          color="white"
          position="relative"
          zIndex="3"
          top="0"
          width="100%"
          display="flex"
          flexDirection="column"
          textAlign="center"
          backgroundImage="linear-gradient(79.88deg, #1B0646 27.59%, #5128AE 100.28%)"
          pt={["12.5rem", null, null, "7rem"]}
          pb={["4.8rem", null, null, "10rem"]}
          variant="slopeTopLeft"
        >
          <Box
            width={["100%", 10 / 12, 8 / 12, 6 / 12]}
            mx="auto"
            mb="5rem"
            textAlign="center"
          >
            <SmallWave display="block" mx="auto" mb="1.2rem" />
            <Heading mb="3.6rem">Our Programs</Heading>
            <Text mt="3.6rem">
              Join the <strong>Mentorship Program</strong> to reap the benefits
              of our industry leading guitar curriculum under the direct
              guidance of our worldclass mentors. Or, if you're new to guitar,
              enroll in our <strong>Prep Program</strong> to get intensive
              ready.
            </Text>
          </Box>
          <Width
            display="flex"
            flexDirection={["column", null, "row", null]}
            textAlign="left"
            alignItems="center"
            flex="1"
          >
            <Box
              backgroundImage="linear-gradient(79.88deg, #5127AE 27.59%, #D04556 100.28%)"
              height="100%"
              borderRadius=".8rem"
              mr={[null, null, "1rem"]}
              width={["100%", "80%", 6 / 12, null]}
              mb={["2rem"]}
            >
              <Box
                width={"100%"}
                borderRadius=".6rem"
                textAlign="center"
                mb={["0rem", null, "0"]}
                bg={color.opal}
                ml={["auto", null, 0, null]}
                mr={["auto", null, "1.8rem", null]}
              >
                <Box
                  width={"100%"}
                  p="2.4rem"
                  borderRadius=".6rem"
                  textAlign="center"
                  mb={"0rem"}
                >
                  <Heading
                    mb=".8rem"
                    mx={["auto", 0, null, null]}
                    color={color.tanzanite}
                    textAlign="center"
                  >
                    Prep Program
                  </Heading>
                  <Text
                    textAlign="center"
                    color={color.tanzanite}
                    fontSize="19px"
                    mb="2.3rem"
                  >
                    6-Week Intensive for{" "}
                    <Span fontWeight="600" color="amethyst">
                      Beginners{" "}
                    </Span>{" "}
                  </Text>
                  <Box textAlign="left">
                    <List bullets="checkmark">
                      <ListItem color="tanzanite">
                        Structured beginner curriculum
                      </ListItem>
                      <ListItem color="tanzanite">
                        2x Weekly live classes with our mentor staff
                      </ListItem>
                      <ListItem color="tanzanite">
                        Unlimited support and guidance
                      </ListItem>
                      <ListItem color="tanzanite">
                        Structured assignments and projects
                      </ListItem>
                      <ListItem color="tanzanite">Facebook Community </ListItem>
                    </List>
                  </Box>
                  <CTA variant="special" to="/prep" mb="1.2rem" mt="3rem">
                    Learn More
                  </CTA>
                </Box>
                {/* <Box
                  backgroundImage="linear-gradient(72.5deg, #651FFF 0%, #D04556 99.51%)"
                  color="white"
                  py="2rem"
                  borderBottomRightRadius=".6rem"
                  borderBottomLeftRadius=".6rem"
                >
                  <Box
                    display="flex"
                    mb="0.6rem"
                    alignItems="center"
                    justifyContent={["center", null]}
                  >
                    <Text fontWeight="bold" fontSize="14px" color="white">
                      UPCOMING COHORT
                    </Text>
                  </Box>
                  <Number>4/12/22</Number>
                </Box> */}
              </Box>
            </Box>
            <Box
              backgroundImage="linear-gradient(79.88deg, #5127AE 27.59%, #D04556 100.28%)"
              height="100%"
              borderRadius=".8rem"
              width={["100%", "80%", 6 / 12, null]}
            >
              <Box
                width={"100%"}
                borderRadius=".6rem"
                textAlign="center"
                mb={["0rem", null, "0"]}
                bg={color.opal}
                ml={["auto", null, 0, null]}
                mr={["auto", null, "1.8rem", null]}
              >
                <Box
                  width={"100%"}
                  p="2.4rem"
                  borderRadius=".6rem"
                  textAlign="center"
                  mb={"0rem"}
                >
                  <Text
                    fontSize="14px"
                    fontWeight="bold"
                    color={color.amethyst}
                    mb=".6rem"
                    textAlign="center"
                  >
                    BY APPLICATION ONLY
                  </Text>
                  <Heading
                    mb=".8rem"
                    mx={["auto", 0, null, null]}
                    color={color.tanzanite}
                    textAlign="center"
                  >
                    Mentorship Program
                  </Heading>
                  <Text
                    textAlign="center"
                    color={color.tanzanite}
                    fontSize="19px"
                    mb="2.3rem"
                  >
                    12-48 Week Intensives for{" "}
                    <Span fontWeight="600" color="amethyst">
                      Intermediates{" "}
                    </Span>{" "}
                  </Text>
                  <Box textAlign="left">
                    <List bullets="checkmark">
                      <ListItem color="tanzanite">
                        Lifetime access to the Core curriculum
                      </ListItem>
                      <ListItem color="tanzanite">
                        Lifetime access to our learning software
                      </ListItem>
                      <ListItem color="tanzanite">
                        4x weekly group classes
                      </ListItem>
                      <ListItem color="tanzanite">
                        Access to our thriving community
                      </ListItem>
                      <ListItem color="tanzanite">
                        Unlimited access to a dedicated mentor
                      </ListItem>
                      <ListItem color="tanzanite">1-on-1 lessons</ListItem>
                      <ListItem color="tanzanite">
                        Daily video feedback on your playing
                      </ListItem>
                    </List>
                  </Box>
                  <CTA
                    variant="special"
                    to="/application"
                    mb="1.2rem"
                    mt="3rem"
                  >
                    Apply Now
                  </CTA>
                  <Box width={["100%", null, null, 7 / 12]} mx="auto">
                    <Text color="tanzanite" fontStyle="italic" variant="small">
                      *Pricing depends on your educational needs, apply for a
                      quote.
                    </Text>
                  </Box>
                </Box>
                <Box
                  backgroundImage="linear-gradient(72.5deg, #651FFF 0%, #D04556 99.51%)"
                  color="white"
                  py="2rem"
                  borderBottomRightRadius=".6rem"
                  borderBottomLeftRadius=".6rem"
                >
                  {/* <Box
                    display="flex"
                    mb="0.6rem"
                    alignItems="center"
                    justifyContent={["center", null]}
                  >
                    <Text fontWeight="bold" fontSize="14px" color="white">
                      UPCOMING COHORT
                    </Text>
                  </Box>
                  <Number>{nextMondayDate}</Number> */}
                </Box>
              </Box>
            </Box>
          </Width>
        </Box>
      </Box>
    </Layout>
  )
}

/* Image and Other GQL Queries */
export const homepageQuery = graphql`
  query {
    levelupImage: file(relativePath: { eq: "levelup.png" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    curriculumImage: file(relativePath: { eq: "curriculum.png" }) {
      childImageSharp {
        fluid(maxWidth: 570, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    communityImage: file(relativePath: { eq: "community.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    locationImage: file(relativePath: { eq: "location.png" }) {
      childImageSharp {
        fixed(width: 14, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const Number = styled.span`
  font-size: 4.2rem;
  line-height: 1.16;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
`

const Extra = styled.span`
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.16;
  color: ${({ theme }) => theme.colors.purpley};
`

const Dollar = styled.span`
  font-weight: 300;
  font-size: 3.4rem;
  line-height: 1.16;
  letter-spacing: 0.01em;
  color: rgba(179, 168, 203, 0.5);
`
